import { ref, reactive, readonly, watchEffect, markRaw } from 'vue'
import web3 from '@/state/web3';
import { BigNumber, ethers } from 'ethers';
import { WEB3_STATE, TX_STATE } from '@/constants';
import { abi as CONTRACT_ABI } from '@/assets/contract/abi.json';
import { address as CONTRACT_ADDRESS } from '@/assets/contract/address.json';

/*
///////////////////////////////////////////////////////////////
												STATE
//////////////////////////////////////////////////////////////
*/

const FAKE_THOUGHTS = markRaw({});

const data = reactive({
	totalSupply: BigNumber.from(0),
	MAX_SUPPLY: BigNumber.from(1000),
	currentPrice: BigNumber.from(0),
	eggBalance: BigNumber.from(0),
	saleIsActive: false,
});

const tx = reactive({
	state: TX_STATE.NONE
});

let ready = ref(false);

/*
///////////////////////////////////////////////////////////////
												PUBLIC
//////////////////////////////////////////////////////////////
*/

const mint = async () => {
	if (!ready.value) { throw { msg: 'try again in a moment', custom: true }; }
	if (data.totalSupply.gte(data.MAX_SUPPLY)) { throw { msg: 'sold out', custom: true }; }

	try {
		// Update tx state
		tx.state = TX_STATE.WAITING;

		// Calculate payment
		const value = await FAKE_THOUGHTS.value.getPrice(data.totalSupply);

		// Estimate gas
		let gasLimit = await FAKE_THOUGHTS.value.connect(web3.user.signer).estimateGas.mint({ value });
		gasLimit = gasLimit.add(gasLimit.div(5)); // Multiply estimate by 20% to ensure transaction goes through

		// Create swap
		const response = await FAKE_THOUGHTS.value.connect(web3.user.signer).mint({ value, gasLimit });
		
		// Await tx confirmation
		tx.state = TX_STATE.PENDING;
		await response.wait(1);

		// Reload contract data
		getData();

		// Update tx state
		tx.state = TX_STATE.SUCCESS;
	} catch (err) {
		tx.state = TX_STATE.NONE;
		throw { msg: err };
	}
}

const init = async () => {
	try {
		// Only init contracts if you have all abis and addresses
		if (CONTRACT_ABI == null || CONTRACT_ADDRESS == null) {
			return;
		}

		// Init contracts
		FAKE_THOUGHTS.value = new ethers.Contract(CONTRACT_ADDRESS, CONTRACT_ABI, web3.user.signer);

		// Get contract data, allowances, and currency decimals
		await getData();

		// Update state
		ready.value = true;
	} catch (err) {
		ready.value = false;
	}
}

const getData = async () => {
	let contractProperties = [
		FAKE_THOUGHTS.value.totalSupply(),
		FAKE_THOUGHTS.value.MAX_SUPPLY(),
		FAKE_THOUGHTS.value.balanceOf(web3.user.address),
		FAKE_THOUGHTS.value.saleIsActive(),
	];
	let propertyNames = ['totalSupply', 'MAX_SUPPLY', 'eggBalance', 'saleIsActive'];

	await Promise.all(contractProperties.map(async (promise, i) => {
		data[propertyNames[i]] = await promise;
	}));

	// Depends on totalSupply, need to get this after
	data.currentPrice = await FAKE_THOUGHTS.value.getPrice(data.totalSupply);
}

/*
///////////////////////////////////////////////////////////////
												WATCHERS
//////////////////////////////////////////////////////////////
*/

watchEffect(() => {
	if (web3.user.connection == WEB3_STATE.CONNECTED) {
		init();
	}
});

/*
///////////////////////////////////////////////////////////////
												EXPORT
//////////////////////////////////////////////////////////////
*/

export default {
	FAKE_THOUGHTS: readonly(FAKE_THOUGHTS),
	data: readonly(data),
	ready: readonly(ready),
	tx: readonly(tx),

	init,
	mint,
};