<template>
  <div class="w-full min-h-screen px-4 flex flex-col items-center relative">
    <div class="w-full max-w-[480px] flex flex-col items-center">
      <Header />
      <Mint />
      <Footer />
    </div>
  </div>
</template>

<script>
import { Header, Footer, Mint } from '@/components'

export default {
  name: 'App',
  components: {
    Header,
    Footer,
    Mint,
  },
}
</script>