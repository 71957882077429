import { BigNumber, ethers } from 'ethers';

export const convertBigNumber = (bigNum) => {
  return Number(ethers.utils.formatEther(bigNum));
}

export const convertToBigNumber = (num) => {
  return BigNumber.from(num);
}

export const parseError = (err) => {
  if (err.custom) {
    return err.msg;
  } else {
    return null;
  }
}

export const formatAddress = (address) => {
  if (address) {
    return `${address.substring(0,6)}`;
  } else {
    return "";
  }
}

export const sleep = (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export const secondsToString = (seconds) => {
  let interval = seconds / 31536000;
  if (interval > 1) {
    return Math.floor(interval) + " years";
  }

  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " months";
  }

  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " days";
  }

  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hours";
  }

  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " minutes";
  }

  return Math.floor(seconds) + " seconds";
}