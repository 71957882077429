<template>
  <div class="w-full mt-8 flex justify-between items-center">
    <p class="font-black text-pink text-[24px] z-10 -mb-1">FAKE THOUGHTS</p>
    <div class="h-12 flex">
      <div class="text-pink px-8 border-2 flex rounded-2xl justify-center items-center flex-1" v-if="web3.user.connection == WEB3_STATE.CONNECTED">
        <p>{{formatAddress(web3.user.address)}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import web3 from '@/state/web3';
import { WEB3_STATE  } from '@/constants';
import { formatAddress } from '@/utils';

export default {
  name: 'Header',
  data() { return { web3, WEB3_STATE, formatAddress, } },
}
</script>