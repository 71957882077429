<template>
  <div class="w-full mt-8 flex-grow flex items-center flex-wrap justify-center">

    <div v-if=" contracts.data.eggBalance.gte(1)" class="w-full text-blue mb-4">
      <div class="w-full h-14 px-5 border-2 rounded-2xl flex items-center">
        <p>@</p>
        <input
          class="font-bold outline-none box-border bg-none bg-darkgrey indent-1 placeholder flex-1"
          spellcheck="false"
          placeholder=" enter any twitter handle"
          @input="username = $event.target.value" />
      </div>
    </div>

    <div
      class="w-full min-h-[304px] mb-4 text-white flex p-5 border-2 rounded-2xl items-start relative"
      :class="{ 'justify-center items-center': twitter.tx.state == TX_STATE.WAITING || twitter.tx.state == TX_STATE.PENDING || twitter.tx.state == TX_STATE.SUCCESS }">
      <div v-if="tweet == null && twitter.tx.state == TX_STATE.NONE && contracts.data.eggBalance.eq(0)" class="flex flex-col items-start">
        <p class="whitespace-pre-wrap">{{ INFO }}</p>
        <span class="hover:underline cursor-pointer" @click="openTwitter()">Soma</span>
      </div>
      <textarea
          v-if="tweet == null && twitter.tx.state == TX_STATE.NONE && contracts.data.eggBalance.gte(1)"
          class="font-medium w-full h-64 outline-none box-border bg-none bg-darkgrey indent-1 placeholder resize-none"
          :class="{ 'text-red': prompt.length > promptMaxLength }"
          spellcheck="false"
          placeholder="enter a short prompt (or leave blank)"
          @input="prompt = $event.target.value" />
      
      <p v-if="tweet != null && twitter.tx.state == TX_STATE.NONE" class="whitespace-pre-wrap" style="overflow-wrap: anywhere">{{ tweet }}</p>
      <div v-if="tweet != null && twitter.tx.state == TX_STATE.NONE" class="left-0 right-0 absolute bottom-5 flex justify-center">
        <p class="opacity-50 hover:opacity-100 hover:underline cursor-pointer" @click="clear">clear</p>
      </div>
      
      <div v-if="twitter.tx.state == TX_STATE.WAITING || twitter.tx.state == TX_STATE.PENDING" class="spinner mr-5"></div>
      <div v-if="twitter.tx.state == TX_STATE.SUCCESS">
        <span>Tweet posted! See it </span><span class="underline cursor-pointer" @click="openTweet()">here.</span>
      </div>
    </div>

    <div class="w-full mb-6 flex flex-col gap-3">
      <div
        v-if="contracts.data.eggBalance.gte(1)"
        class="h-16 flex gap-3"
        :class="{ 'opacity-50 pointer-events-none': twitter.tx.state == TX_STATE.WAITING || twitter.tx.state == TX_STATE.PENDING }">
        <button class="btn btn-purple-flipped flex-1" @click="generateTweet">Generate Tweet</button>
        <button class="btn btn-blue-flipped flex-1" @click="postTweet">Post Tweet</button>
      </div>
      <div v-if="web3.user.connection == WEB3_STATE.CONNECTED" class="h-16 flex">
        <button
          v-if="contracts.data.saleIsActive && (contracts.tx.state == TX_STATE.NONE || contracts.tx.state == TX_STATE.SUCCESS) && contracts.data.totalSupply.lt(contracts.data.MAX_SUPPLY)"
          class="btn btn-green-flipped flex-1"
          @click="contracts.mint()">Mint egg</button>
        <div
          v-if="contracts.data.saleIsActive && (contracts.tx.state == TX_STATE.WAITING || contracts.tx.state == TX_STATE.PENDING) && contracts.data.totalSupply.lt(contracts.data.MAX_SUPPLY)"
          class="text-green px-8 border-2 flex font-medium rounded-2xl justify-center items-center flex-1">
          <div class="spinner mr-5"></div>
          <p v-if="contracts.tx.state == TX_STATE.WAITING">waiting...</p>
          <p v-if="contracts.tx.state == TX_STATE.PENDING">tx pending...</p>
        </div>
      </div>

      <button class="btn btn-pink h-16 border-[2px]" @click="install" v-if="web3.user.connection == WEB3_STATE.INSTALL">Install Wallet</button>
      <button class="btn btn-pink h-16 border-[2px]" @click="changeNetwork" v-if="web3.user.connection == WEB3_STATE.WRONG_NETWORK">Wrong Network</button>
      <button class="btn btn-pink h-16 border-[2px]" @click="web3.connectWallet" v-if="web3.user.connection == WEB3_STATE.CONNECT">Connect 
        Wallet</button>
      
      <p v-if="error != null" class="text-red text-center mt-2 whitespace-pre-wrap">{{ error }}</p>
    </div>

    <div class="w-full h-[1px] bg-grey"/>

    <div class="flex flex-col gap-4 my-6 text-white">
      <p v-if="contracts.data.totalSupply.lt(contracts.data.MAX_SUPPLY)" class="text-center">{{ contracts.data.totalSupply }} / 1,000 minted</p>
      <p v-if="contracts.data.totalSupply.lt(contracts.data.MAX_SUPPLY)" class="text-center">Current price: {{ ethers.utils.formatUnits(contracts.data.currentPrice) }} ETH</p>
      <p v-if="contracts.data.totalSupply.gte(contracts.data.MAX_SUPPLY)" class="text-center">Sold out!</p>
      <p class="text-center">You own {{ contracts.data.eggBalance }} {{ (contracts.data.eggBalance == 1) ? 'egg' : 'eggs' }}</p>
    </div>

    <div class="w-full h-[1px] bg-lightgrey"/>

    <div id="twitter">
      <a class="twitter-timeline" data-width="480" data-theme="dark" href="https://twitter.com/fakethoughtsnft?ref_src=twsrc%5Etfw">Tweets by fakethoughtsnft</a>
    </div>
  </div>
</template>

<script>
import { ethers } from 'ethers';
import contracts from '@/state/contracts';
import twitter from '@/state/twitter';
import web3 from '@/state/web3';
import { secondsToString } from '@/utils';
import { WEB3_STATE, TX_STATE, INSTALL_METAMASK_URL, SOMA_TWITTER_URL, CHAIN_ID, ETHERSCAN_BASE_URL, TWEET_BASE_URL, INFO } from '@/constants';
import { address as CONTRACT_ADDRESS } from '@/assets/contract/address.json';

export default {
  name: 'Mint',
  data() {
    return {
      contracts, twitter, web3, WEB3_STATE, TX_STATE, INFO, ethers,
      username: '',
      tweet: null,
      tweetId: null,
      prompt: '',
      promptMaxLength: 16,
      error: null,
    }
  },
  mounted() {
    let twitterEmbed = document.createElement('script');
    twitterEmbed.src = 'https://platform.twitter.com/widgets.js';
    twitterEmbed.async = true;
    document.getElementById('twitter').appendChild(twitterEmbed);
  },
  methods: {
    install() {
      window.open(INSTALL_METAMASK_URL, '_blank').focus();
    },
    changeNetwork() {
      window.ethereum.request({ method: 'wallet_switchEthereumChain', params:[{ chainId: `0x${CHAIN_ID.toString(16)}` }]});
    },
    openTweet() {
      window.open(`${TWEET_BASE_URL}/${this.tweetId}`, '_blank').focus();
    },
    openAddress() {
      window.open(`${ETHERSCAN_BASE_URL}/address/${CONTRACT_ADDRESS}`, '_blank').focus();
    },
    openTwitter() {
      window.open(SOMA_TWITTER_URL, '_blank').focus();
    },

    async generateTweet() {
      this.error = null;

      // Validate username
      const twitterUsernameRegex = /^(\w){1,15}$/;
      if (this.username == null || this.username.length == 0 || !twitterUsernameRegex.test(this.username)) {
        this.error = 'Invalid username.';
        return;
      }

      // Validate prompt
      if (this.prompt != null && this.prompt.length > this.promptMaxLength) {
        this.error = `Prompt too long (max ${this.promptMaxLength} characters).`;
        return;
      }

      try {
        this.tweet = await this.twitter.generateTweet(this.username, this.prompt);
      } catch (e) {
        this.error = e;
      }
    },
    async postTweet() {
      this.error = null;

      if (this.tweet == null) {
        this.error = "No tweet generated yet.";
        return;
      }

      try {
        this.tweetId = await this.twitter.postTweet();
      } catch (e) {
        if (e.message != null && e.lastTweeted != null) {
          const currentTime = Math.round(Date.now() / 1000);
          const timeLeft = (60 * 60 * 24) - (currentTime - e.lastTweeted);
          if (timeLeft > 0) {
            this.error = `You must wait ${secondsToString(timeLeft)} before tweeting again.\n(Or you can get another egg!)`;
          } else {
            this.error = 'Try again.';
          }
        } else {
          this.error = e;
        }
      }
    },
    clear() {
      this.tweet = null;
      this.prompt = '';
    },
  },
}
</script>

<style scoped>

.placeholder::placeholder {
  opacity: 0.7;
  font-weight: 500;
  color: inherit;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type=number] {
  -moz-appearance: textfield;
}

</style>