// URLs
export const API_URL = "https://v8rkgl4em3.execute-api.us-west-2.amazonaws.com";

export const TWITTER_URL = "https://twitter.com/fakethoughtsnft";
export const SOMA_TWITTER_URL = "https://twitter.com/somamine";
export const INSTALL_METAMASK_URL = "https://metamask.io/download.html";
export const ETHERSCAN_BASE_URL = "https://etherscan.io";
export const TWEET_BASE_URL = "https://twitter.com/fakethoughtsnft/status";

// Web3
export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000';

export const CHAIN_ID = 1;
// export const CHAIN_ID = 4; // rinkeby

export const WEB3_STATE = {
    INSTALL: 0,
    CONNECT: 1,
    CONNECTED: 2,
    WRONG_NETWORK: 3
};

export const TX_STATE = {
    NONE: 0,
    WAITING: 1,
    PENDING: 2,
    SUCCESS: 3,
};

// Copy
export const INFO = `Mint an egg to create AI-generated tweets.\n\nPost any tweet you generate to the @fakethoughtsnft account once per day.\n\nEggs start at 0.1 ETH, and increase by 0.1 ETH every 100 mints.\n\nglhf,`;