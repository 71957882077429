import { reactive, ref, readonly } from 'vue'
import web3 from '@/state/web3';
import { SiweMessage } from 'siwe';
import jwt_decode from 'jwt-decode';
import { sleep } from '@/utils';
import { API_URL, CHAIN_ID, TX_STATE } from '@/constants';

/*
///////////////////////////////////////////////////////////////
												STATE
//////////////////////////////////////////////////////////////
*/

const twitter = reactive({
	index: 0,
	username: null,
	prompt: '',
	tweets: [],
});

const tx = reactive({
	state: TX_STATE.NONE
});

let signedIn = ref(false);

/*
///////////////////////////////////////////////////////////////
												PUBLIC
//////////////////////////////////////////////////////////////
*/

const generateTweet = async (username, prompt) => {
	try {
		// Init state
		tx.state = TX_STATE.NONE;

		// Sign in if not yet signed in
		if (!signedIn.value) {
			tx.state = TX_STATE.WAITING;
			await signInWithEthereum();
		}

		// Reset data if username or prompt changed
		if (twitter.username != username || twitter.prompt != prompt) {
			// console.log(`new username: ${username}`);
			// console.log(`new prompt: ${prompt}`);
			twitter.index = 0;
			twitter.username = username;
			twitter.prompt = prompt;
			twitter.tweets = [];
		}

		// Keep track of whether to fake sleep or not
		let shouldSleep = true;

		// Fetch new batch if local one doesn't exist
		if (twitter.tweets.length == 0) {
			// console.log('fetch new batch');
			tx.state = TX_STATE.PENDING;
			await fetchTweets(username, prompt);
			tx.state = TX_STATE.NONE;
			twitter.index = 0;
			shouldSleep = false;
		}

		// Get current batch
		let currentBatch = twitter.tweets[0].signedData.generatedTweets;

		// Decide whether to get more tweets and from where
		if (twitter.index >= currentBatch.length && twitter.tweets.length > 1) {
			// Load next local batch
			// console.log('moved to next local batch');
			twitter.tweets.shift();
			currentBatch = twitter.tweets[0].signedData.generatedTweets;
			twitter.index = 0;
		} else if (twitter.index >= currentBatch.length && twitter.tweets.length == 0) {
			// Fetch and wait for new batch
			// console.log('end of batch, fetch more tweets');
			tx.state = TX_STATE.PENDING;
			await fetchTweets(username, prompt);
			tx.state = TX_STATE.NONE;
			twitter.index = 0;
			shouldSleep = false;
		} else if (twitter.index == (currentBatch.length - 5)) {
			// Fetch next batch in the background
			// console.log('fetch tweets in background');
			fetchTweets(username, prompt);
		}

		// Sleep
		if (shouldSleep) {
			// console.log('sleeping');
			tx.state = TX_STATE.PENDING;
			const sleepFor = Math.floor(Math.random() * (750 - 250 + 1)) + 250;
			await sleep(sleepFor);
			tx.state = TX_STATE.NONE;
		}

		// Get tweet from current batch
		let tweet = currentBatch[twitter.index];
		twitter.index++;
		
		return tweet;
	} catch (e) {
		// console.error(e);
		tx.state = TX_STATE.NONE;

		if (e.message == 'Failed to sign in.') { throw e.message; }
		if (e.message == 'Expected username.') { throw e.message; }
		if (e.message == 'Invalid prompt.') { throw e.message; }
		if (e.message == 'Failed to authenticate user.') { throw 'Failed to sign in.'; }
		if (e.message == 'User does not own an egg.') { throw 'You do not own an egg.'; }
		
		throw 'Failed to generate tweet.'
	}
}

const postTweet = async () => {
	if (twitter.tweets.length == 0 || twitter.index < 0 || twitter.index >= twitter.tweets[0].signedData.generatedTweets.length) {
		throw 'Failed to post tweet.';
	}

	try {
		// Update state
		tx.state = TX_STATE.PENDING;

		// Generate tweets
		const response = await fetch(`${API_URL}/postTweet`, {
			method: "POST",
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				data: twitter.tweets[0].signedData,
				tweet_jwt: twitter.tweets[0].tweet_jwt,
				index: (twitter.index - 1),
			}),
			credentials: 'include'
		});
		const result = await response.json();

		// Handle response
		if (!response.ok) { throw result; }
		// console.log('posted tweet');

		// Update state
		tx.state = TX_STATE.SUCCESS;

		return result.tweetId;
	} catch (e) {
		// console.error(e);
		tx.state = TX_STATE.NONE;

		if (e.message == 'User does not own an egg.') { throw 'You do not own an egg.'; }
		if (e.message != null && e.message.message != null && e.message.lastTweeted != null && e.message.message == 'User has no eggs that are ready.') { throw e.message; }
		
		throw "Failed to post tweet.";
	}
}

/*
///////////////////////////////////////////////////////////////
												INTERNAL
//////////////////////////////////////////////////////////////
*/


const signInWithEthereum = async () => {
	try {
		// Get nonce
		const response = await fetch(`${API_URL}/nonce`);
		const result = await response.json();
		if (!response.ok) { throw result; }
		const { nonce_jwt } = result;
		const decoded = jwt_decode(nonce_jwt);

		// Prepare message
		const message = new SiweMessage({
			domain: window.location.host,
			address: web3.user.address,
			statement: 'Sign in with Ethereum to FAKE THOUGHTS.',
			uri: window.location.origin,
			version: '1',
			chainId: CHAIN_ID,
			nonce: decoded.nonce
		}).prepareMessage();

		// Sign message
		const signature = await web3.user.signer.signMessage(message);

		// Update state
		tx.state = TX_STATE.PENDING;

		// Send signature
		await fetch(`${API_URL}/verify`, {
			method: "POST",
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ message, signature, nonce_jwt }),
			credentials: 'include'
		});
		if (!response.ok) { throw result; }

		// Update state
		signedIn.value = true;
		// console.log('authenticated user!');
	} catch (e) {
		// console.error(e);
		throw 'Failed to sign in.';
	}
}

const fetchTweets = async (username, prompt) => {
	// console.log(`fetching tweets for ${username}...`);

	// Generate tweets
	const response = await fetch(`${API_URL}/generateTweets`, {
		method: "POST",
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			username,
			prompt
		}),
		credentials: 'include'
	});
	const result = await response.json();

	// Handle response
	if (!response.ok) { throw result; }

	// Keep only if username and prompt unchanged
	if (twitter.username == username && twitter.prompt == prompt) {
		// console.log('pushed new batch');
		twitter.tweets.push({
			signedData: result.data,
			tweet_jwt: result.tweet_jwt
		});
	} else {
		// console.log('got new batch, but username changed');
	}
}

/*
///////////////////////////////////////////////////////////////
												EXPORT
//////////////////////////////////////////////////////////////
*/

export default {
	tx: readonly(tx),

	generateTweet,
	postTweet,
};